.contact-details-wrapper {
    width: 100%;
    .details-title {
        font-size: 16px;
    }

    .contact-info-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 10px 0;

        .contact-icon {
            margin-right: 20px;
        }

        .contact-info {
            text-align: left;
        }
    }
}
