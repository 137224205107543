@import "../../styles/imports.scss";

.checkbox-box {
    width: vw(18, $desktop_max);
    height: vw(18, $desktop_max);
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;

    &.checked {
        background-color: $site-primary;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    .check-mark {
        width: 100%;
        height: 100%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }
}
