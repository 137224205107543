@import "../../../styles/imports.scss";

.galley-wrapper {
    width: vw(1344, $desktop_max);
    height: vw(728, $desktop_max);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        color: $site-primary;
        font-size: vw(12, $desktop_max);
        font-weight: bold;
        text-decoration: none;
        margin-top: vw(10, $desktop_max);
    }
}
