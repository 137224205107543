@import "../../../styles/imports.scss";

.footer-wrapper {
    margin-top: vw(25, $mobile);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    // position: sticky;
    // bottom: 0;
    padding: vw(20, $mobile) 0;

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .logo-image {
            width: vw(50, $mobile);
        }

        .logo-text-wrapper {
            margin-top: vw(5, $mobile);
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            left: vw(2, $mobile);

            .main-line {
                position: relative;
                left: vw(2, $mobile);
                font-weight: bold;
                font-size: vw(14, $mobile);
                letter-spacing: vw(5, $mobile);
            }

            .sub-line {
                font-weight: bold;
                letter-spacing: vw(3, $mobile);
                font-size: vw(12, $mobile);
            }
        }
    }

    .socialmedia-wrapper {
        display: flex;

        .socialmedia-item {
            cursor: pointer;
            font-size: vw(20, $mobile);
            margin: vw(5, $mobile) vw(10, $mobile);
            color: white;
        }
    }

    .login-section {
        cursor: pointer;
        font-size: vw(9, $mobile);
        color: $site-primary;
        text-transform: uppercase;
        text-decoration: none;
    }

    .copyrights {
        margin: vw(10, $mobile) 0;
        // margin-bottom: 0;
        font-size: vw(8, $mobile);
        width: 50%;
        text-align: center;
    }

    .terms-and-privacy {
        margin-bottom: vw(10, $mobile);
        display: flex;
        align-items: baseline;

        .seperator {
            margin-inline: vw(5, $mobile);
            font-size: vw(9, $mobile);
        }
    }
}


@media (min-width: $tablet_px) {
    
}
@media (min-width: $desktop_px) {
    .footer-wrapper {
        margin-top: vw(50, $desktop_max);
        min-height: vw(200, $desktop_max);
        padding: vw(30, $desktop_max) 0;
    
        .logo-wrapper {
            .logo-image {
                width: vw(70, $desktop_max);
            }
    
            .logo-text-wrapper {
                margin-top: vw(5, $desktop_max);
                left: vw(2, $desktop_max);
    
                .main-line {
                    left: vw(5, $desktop_max);
                    font-size: vw(18, $desktop_max);
                    letter-spacing: vw(10, $desktop_max);
                }
    
                .sub-line {
                    letter-spacing: vw(4, $desktop_max);
                    font-size: vw(14, $desktop_max);
                }
            }
        }
    
        .socialmedia-wrapper {
    
            .socialmedia-item {
                font-size: vw(20, $desktop_max);
                margin: vw(5, $desktop_max) vw(10, $desktop_max);
            }
        }
    
        .login-section {
            font-size: vw(8, $desktop_max);
        }
    
        .copyrights {
            margin: vw(10, $desktop_max) 0;
            // margin-bottom: 0;
            font-size: vw(10, $desktop_max);
        }
    
        .terms-and-privacy {
            margin-bottom: vw(10, $desktop_max);
    
            .seperator {
                margin-inline: vw(5, $desktop_max);
                font-size: vw(10, $desktop_max);
            }
        }
    }
    
}
@media (min-width: $desktop_large_px) {
    
}
@media (min-width: $desktop_max_px) {
    
}