@import '../../../styles/imports.scss';

.about-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;

    .about-right {

        .about-paragraph {
            padding-inline: vw(20, $mobile);
            text-align: justify;
        }
    }

    .about-left {
        display: flex;
        align-items: center;
        flex-direction: column;

        .about-pic {
            width: vw(100, $mobile);
            border-radius: 50%;
        }
    }
}


@media (min-width: $tablet_px) {
    
}
@media (min-width: $desktop_px) {
    .about-wrapper {
        max-height: 60%;
        max-width: 40%;
        padding: 20px;
        flex-direction: row;
    
        .about-right {
            margin-left: 20px;
    
            .about-paragraph {
                text-align: justify;
            }
        }
    
        .about-left {
            
    
            .about-pic {
                width: 300px;
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    
}
@media (min-width: $desktop_max_px) {
    
}
