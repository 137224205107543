.editable-overlay-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .backdrop {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 50px;
        color: rgba(0,0,0,0.5);
        background-color: #e7e7e7;

    }

    .popup {
        // width: 50%;
        width: 400px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.4);    
        border-radius: 10px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 1;
        padding: 20px 25px;

        .popup-title {
            font-family: Nunito;
            font-size: 22px;
            margin-bottom: 10px;
        }

        .text-input {
            font-size: 16px;
            height: 30px;
            width: 70%;
            border: 1px solid #cecece;
        }

        .btns-wrapper {
            display: flex;
            align-self: flex-end;
            & > *:first-child{
                margin-right: 10px;
            }
            .btn {
                padding: 10px 20px;
                font-size: 16px;
                border-radius: 10px;
                border: 1px solid #d7d7d7;
                cursor: pointer;

            }
        }
    }

}