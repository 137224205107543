.popup-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: 0.3s;
        cursor: pointer;

        &.fade-in {
            opacity: 1;
        }
    }
}
