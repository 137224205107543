@import "../../styles/imports.scss";

.back-to-top-wrapper {
    position: fixed;
    right: vw(30, $mobile);
    bottom: vw(-150, $mobile);
    width: vw(50, $mobile);
    height: vw(50, $mobile);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 0.5s;

    &.slide-in {
        bottom: vw(30, $mobile);
    }

    .arrow-up {
        width: vw(24, $mobile);
        height: vw(24, $mobile);
        color: $site-primary;
    }
}

@media (min-width: $tablet_px) {
    
}
@media (min-width: $desktop_px) {
    .back-to-top-wrapper {
        
        right: vw(50, $desktop_max);
        bottom: vw(-150, $desktop_max);
        width: vw(50, $desktop_max);
        height: vw(50, $desktop_max);
    
        &.slide-in {
            bottom: vw(50, $desktop_max);
        }
    
        .arrow-up {
            width: vw(24, $desktop_max);
            height: vw(24, $desktop_max);
        }
    }  
}
@media (min-width: $desktop_large_px) {
    
}
@media (min-width: $desktop_max_px) {
    
}