@import "../../styles/imports.scss";

.bubble-link-wrapper {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    transition: 0.2s;
    cursor: pointer;

    .title {
        z-index: 100;
        transition: 0.2s;
        transition-delay: 0.6s;
    }
}
