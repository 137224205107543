.editor-wrapper {
    min-height: 100vh;
    position: relative;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: white;
    width: 80%;
    top: 5vw;

    .toolbar-wrapper {
        position: fixed;
        z-index: 70;
        top: 6vw;
        right: 11vw;
        display: flex;
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        
    }

    .fields-wrapper {
        padding: 10px 20px;
        input{
            display: block;
            margin-bottom: 10px;
            border-radius: 10px;
            border: 1px solid lightgray;
            padding-inline: 10px;
        }
        
        .title{
            height: 2rem;
            font-size: 1rem;
            width: 70%;
        }

        .subtitle{
            height: 1.5rem;
            font-size: 0.75rem;
            width: 60%;
        }

        .author {
            height: 1.5rem;
            font-size: 0.75rem;
            width: 30%;
        }
    }
}