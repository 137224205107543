@import "../../styles/imports.scss";

$current: $mobile;

.horizontal-indicator {
    width: 100%;
    position: absolute;
    height: vw(5, $current);
    bottom: vw(-5, $current);
    z-index: 98;

    .progress-bar {
        background-color: $site-primary;
        height: 100%;
        border-radius: 0 10px 10px 0;
    }
}


@media (min-width: $tablet_px) {
    $current: $tablet;

    .horizontal-indicator {
        height: vw(5, $current);
        bottom: vw(-5, $current);
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;

    .horizontal-indicator {
        height: vw(5, $current);
        bottom: vw(-5, $current);
    }
    
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;

    .horizontal-indicator {
        height: vw(5, $current);
        bottom: vw(-5, $current);
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;

    .horizontal-indicator {
        height: vw(5, $current);
        bottom: vw(-5, $current);
    }
}