@import "../../styles/imports.scss";

.float-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: vw(5, $desktop_max) 0 vw(10, $desktop_max) 0;
    // border: 1px solid black;

    .float-label-background {
        background-color: white;
        position: absolute;
        left: vw(5, $desktop_max);
        top: vw(-5, $desktop_max);
        max-width: 100%;
        transform: scaleX(0);
        transform-origin: center;
        transition: 0.2s ease-in-out;
        &.active {
            transform: scaleX(1);
        }
    }

    .float-label {
        position: absolute;
        padding: 0 10px 0 5px;
        left: vw(5, $desktop_max);
        top: vw(10, $desktop_max);
        transition: 0.2s 0.1s;

        &.active {
            top: vw(-5, $desktop_max);
            font-size: 12px;

            .required-indicator {
                font-size: 10px;
            }
        }

        .required-indicator {
            color: $site-primary;
            font-weight: bold;
            vertical-align: middle;
            font-size: 12px;
            margin-left: vw(2.5, $desktop_max);
        }
    }
    .input-wrapper {
        display: flex;
        align-items: center;
        .float-input {
            width: 100%;
            // border-radius: 5px;
            padding: 0 5px;
            background-color: white;
            height: 2rem;
            font-size: 1rem;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-sizing: border-box;

            &.error {
                border: 1px solid red;
            }
        }

        .float-input:focus {
            border: 1px solid green;
        }

        .float-error-icon {
            position: absolute;
            height: vw(15, $desktop_max);
            right: vw(7.5, $desktop_max);
            opacity: 0;
            transition: 0.2s;

            &.show {
                opacity: 1;
            }
        }
    }
    .error-wrapper {
        display: flex;
        position: absolute;
        width: fit-content;
        left: 105%;

        .arrow-left {
            width: 0;
            height: 0;
            position: relative;
            z-index: 10;
            border-left: 0 solid red;
            border-right: 0.8rem solid red;
            border-bottom: 0.8rem solid transparent;
            border-top: 0.8rem solid transparent;
            margin-left: auto;
        }

        .float-error {
            padding-inline: 5px;
            color: white;
            display: flex;
            align-items: center;
            background-color: red;
            border-radius: 0 10px 10px 0;
            font-size: 12px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        }
    }
}
