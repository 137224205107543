@import "../../../../styles/imports.scss";

.navigator-wrapper {
    display: flex;
    height: 100%;
    position: relative;

    .navigator-strip {
        width: vw(70, $desktop_max);
        padding: vw(20, $desktop_max) 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 10;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #efefef;

        .menu-icon {
            color: black;
            width: 100%;
            padding: vw(10, $desktop_max) 0;
            display: flex;
            align-items: center;
            justify-content: center;
            & > * {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
            &:first-child {
                border: none;
            }
            &:hover {
                color: $site-primary;
            }
        }

        .navigator-menu-btn {
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: $site-primary;
            }
        }

        .logout-area {
            position: absolute;
            bottom: vw(10, $desktop_max);
            // border: 1px solid black;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .navigator-texts {
        height: 100%;
        // border: 1px solid red;
        padding-top: vw(61, $desktop_max);
        background-color: #e1e1e1;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: vw(70, $desktop_max);
        z-index: 9;
        overflow: hidden;
        white-space: nowrap;
        width: 0;
        transition: 0.5s;

        &.open {
            width: vw(200, $desktop_max);
        }

        .logout-area {
            position: absolute;
            bottom: 0;
            // bottom: vw(10, $desktop_max);
        }
    }
}
