@import "../../../styles/imports.scss";

.splash-wrapper {
    width: vw(175, $mobile);
    text-shadow: 0 0 5px rgb(0, 0, 0);
    font-size: vw(24, $mobile);
    text-align: center;
    font-weight: bold;

    .logo-image {
        width: vw(100, $mobile);
    }

    .text {
        color: white;
    }

    .icon {
        color: $site-primary;
        & > * {
            cursor: pointer;
        }
    }
}

@media (min-width: $tablet_px) {
    
}
@media (min-width: $desktop_px) {
    .splash-wrapper {
        width: vw(400, $desktop_max);
        font-size: vw(50, $desktop_max);
    
        .logo-image {
            width: vw(200, $desktop_max);
        }
    }
}
@media (min-width: $desktop_large_px) {
    
}
@media (min-width: $desktop_max_px) {
    
}