@import "../../../styles/imports.scss";

.cell-wrapper {
    display: flex;
    align-items: stretch;
    padding: vw(5, $desktop_max) vw(15, $desktop_max);

    &.header {
        font-weight: bold;
    }
}
