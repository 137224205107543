.editable-video-wrapper {
    position: relative;
    .iframe-wrapper {
        height: 500px;
        width: 100%;

        .test { 
            width: 100%;
            height: 100%;
            background-color: #e6e6e6;
        }
        

        .remove-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            
            &:hover {
                color: red;
            }
        }

    }
}