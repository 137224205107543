@import "../../../styles/imports.scss";

.buttons-wrapper {
    margin-top: vw(10, $desktop_max);
    display: flex;
    :not(:last-child) {
        margin-right: vw(20, $desktop_max);
    }

    .cancel-btn {
        background-color: #d64242;

        &:hover {
            background-color: #ff0000;
        }
    }
}
