@import "../../styles/imports.scss";

.table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .table-header-wrapper {
        display: flex;
        align-items: center;
        background-color: #efefef;

        .table-header-cell {
            flex: 1;
            padding: vw(5, $desktop_max) vw(15, $desktop_max);
            border-left: 1px solid rgba(0, 0, 0, 0.1);

            &:first-child {
                border-left: none;
            }
            &.small {
                flex: 0;
                color: #efefef;
            }
        }
    }

    .table-row {
        display: flex;
        align-items: stretch;
        vertical-align: top;
        transition: 0.2s;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &.even {
            background-color: #f7f7f7;
        }

        &:hover {
            background-color: #d9d9d9;
        }
        .trash-bin {
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: rgb(255, 77, 77);
            }
        }
        .check-box {
            justify-content: center;
        }
    }

    .no-result-msg {
        margin-top: vw(10, $desktop_max);
        align-self: center;
    }
}
