@import "../../styles/imports.scss";

.image-grid-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(8, 5vw);
    grid-template-rows: repeat(8, 5vw);
    grid-gap: vw(10, $desktop_max);
    overflow: hidden;

    .image-grid-item {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;

        &.no-0 {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 5;
        }
        &.no-1 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        &.no-2 {
            grid-column-start: 9;
            grid-column-end: 11;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        &.no-3 {
            grid-column-start: 11;
            grid-column-end: 14;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        &.no-4 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 5;
            grid-row-end: 8;
        }
        &.no-5 {
            grid-column-start: 3;
            grid-column-end: 8;
            grid-row-start: 5;
            grid-row-end: 8;
        }
        &.no-6 {
            grid-column-start: 5;
            grid-column-end: 8;
            grid-row-start: 3;
            grid-row-end: 5;
        }
        &.no-7 {
            grid-column-start: 8;
            grid-column-end: 12;
            grid-row-start: 3;
            grid-row-end: 8;
        }
        &.no-8 {
            grid-column-start: 12;
            grid-column-end: 14;
            grid-row-start: 3;
            grid-row-end: 5;
        }
        &.no-9 {
            grid-column-start: 12;
            grid-column-end: 14;
            grid-row-start: 5;
            grid-row-end: 8;
        }
    }
}
