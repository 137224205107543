@import "../../styles/imports.scss";

.dashboard-wrapper {
    width: 100%;
    height: 80vh;
    margin: vw(50, $desktop_max) 0;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    display: flex;

    .outlet-wrapper {
        padding: vw(30, $desktop_max);
        width: 100%;
        height: 100%;
    }
}

@media (min-width: $tablet_px) {
}
@media (min-width: $desktop_px) {
    .dashboard-wrapper {
        width: 70vw;
        height: 80vh;
        margin: vw(50, $desktop_max) 0;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        margin-top: vw(130, $desktop_max);

        .outlet-wrapper {
            padding: vw(30, $desktop_max);
            width: 100%;
            height: 100%;
        }
    }
}
@media (min-width: $desktop_large_px) {
}
@media (min-width: $desktop_max_px) {
}