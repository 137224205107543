@import '../../styles/imports.scss';

$current: $mobile;
.drawer-navigator-wrapper {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;

    &.open {
        pointer-events: all;
    }

    .backdrop {   
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: 0.3s;

        &.open {
            opacity: 1;
        }
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0;
        background-color: rgb(255,255,255);
        color: black;
        z-index: 0;
        transition: 0.3s;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.8);

        &.open {
            overflow: unset;
            width: 80vw;
        }

        .item {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: vw(20, $current) vw(20, $current);
            font-size: vw(18, $current);
            border-bottom: 1px solid rgba(0,0,0,0.2);

            &.right {
                justify-content: flex-end;   
                font-size: vw(24, $current); 
            }

            .clickable {
                cursor: pointer;
            }
        }
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .drawer-navigator-wrapper {
        .overlay {
            &.open {
                width: 30vw;
            }
            .item {
                padding: vw(20, $current) vw(20, $current);
                font-size: vw(18, $current);
                &.right {
                    font-size: vw(24, $current); 
                }
            }
        }
    }
}
@media (min-width: $desktop_px) {
    .drawer-navigator-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .drawer-navigator-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .drawer-navigator-wrapper {
    }
}