@import "../../../styles/imports.scss";

.contact-wrapper {
    height: 70vh;
    position: relative;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .contact-form-wrapper {
        display: flex;
        background-color: white;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .contact-form {
            padding: vw(30, $desktop_max);
            position: relative;
            z-index: 2;

            .contact-title {
                padding: 0;
                margin: vw(10, $desktop_max) 0;
            }
            .field {
                margin-bottom: 0%;
            }
        }
    }

    .contact-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        overflow: hidden;

        .contact-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
