@import "../../../styles/imports.scss";

.popup-login-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-form {
        width: 100%;
        .popup-input {
            width: 100%;
        }

        .forgot-password {
            text-align: center;
            color: $site-primary;
            margin-bottom: vw(10, $desktop_max);
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    .yellow-line {
        width: 100%;
        opacity: 0.3;
    }

    .log-in-method {
        display: flex;
        justify-content: space-around;
        margin: 5px 0;
        border-radius: 10px;

        &.google {
            background-color: rgb(239, 69, 69);

            &:hover {
                background-color: rgb(211, 54, 54);
            }
        }
        &.facebook {
            background-color: rgb(74, 74, 217);

            &:hover {
                background-color: rgb(60, 60, 178);
            }
        }
    }

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: vw(15, $desktop_max);

        .logo-image {
            width: 70px;
        }

        .logo-text-wrapper {
            margin-top: 5px;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            left: 2px;

            .main-line {
                position: relative;
                left: 5px;
                font-weight: bold;
                font-size: 18px;
                letter-spacing: 0.5vw;
            }

            .sub-line {
                font-weight: bold;
                letter-spacing: 0.2vw;
                font-size: 14px;
            }
        }
    }
}
