@import "../../styles/imports.scss";

.state-indicator {
    position: absolute;
    z-index: 4;
}

@media (min-width: 768px) {
    .state-indicator {
        z-index: 3;
    }
}
@media (min-width: 1200px) {
    .state-indicator {
        z-index: 2;
    }
}
@media (min-width: 1500px) {
    .state-indicator {
        z-index: 1;
    }
}
@media (min-width: 1920px) {
    .state-indicator {
        z-index: 0;
    }
}
