@import "../../styles/imports.scss";

$radius: 10px;

$menu-items: 4;
$menu-items-loop-offset: $menu-items - 1;
$height: (100 / $menu-items) * 1%;

.bubble-wrapper {
    position: absolute;
    z-index: 99;
    top: 1.9vw;
    right: 17px;
    height: 0;
    // overflow: hidden;
    transition: 0.2s;

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 150px solid transparent;
        border-right: 0 solid transparent;

        border-bottom: 7.5px solid white;
        margin-left: auto;
    }

    .main {
        box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
        border-radius: $radius 0 $radius $radius;
        padding: 10px 20px;
        background-color: white;
        min-width: 100px;
        display: flex;
        flex-direction: column;
        position: relative;

        .indicator {
            position: absolute;
            width: 100%;
            left: 0;
            height: 2vw;
            background-color: $site-primary;
        }
    }
}
