@import "../../styles/imports.scss";

.screen {
    width: 100%;
    min-height: 45vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.first {
        margin-top: vw(50, $mobile);
    }
}


@media (min-width: $tablet_px) {
    
}

@media (min-width: $desktop_px) {
    .screen {
        width: unset;
        height: vw(1080, $desktop_max);

        &.first {
            margin-top: vw(80, $desktop_max);
        }
    }
}

@media (min-width: $desktop_large_px) {
    
}

@media (min-width: $desktop_max_px) {
    
}
