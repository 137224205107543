@import "../../../../../../styles/imports.scss";

.side-nav-tab-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: vw(10, $desktop_max) 0;
    width: 100%;
    text-decoration: none;
    color: #000;
    background-color: #efefef;
    cursor: pointer;

    &.hover {
        color: $site-primary;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
    }

    .label-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: vw(70, $desktop_max);
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        background-color: #efefef;
        box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0 10px 10px 0;
        transition: 0.5s;
        padding: 0 0;
        width: 0;
        height: 100%;

        &.hover {
            color: black;
            width: vw(150, $desktop_max);
            padding: 0 vw(15, $desktop_max);
        }
    }
}
